var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "developRM home" }, [
    _c("div", { staticClass: "develop_top" }, [
      _c("h2", [
        _vm._v(" " + _vm._s(_vm.$t("technology_title")) + " "),
        _c("br"),
        _vm._v(_vm._s(_vm.$t("technology_title_1")) + " ")
      ]),
      _c("img", {
        attrs: {
          src: require("@/assets/images/reviewmind/reviewmindDemo.gif"),
          alt: ""
        }
      }),
      _c("div", { staticClass: "develop_img" }, [
        _c("ul", [
          _c("li", [
            _c("h3", [_vm._v(_vm._s(_vm.$t("technology_AI")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("technology_AI_1")))])
          ]),
          _c("li", [
            _c("h3", [_vm._v(_vm._s(_vm.$t("technology_NLP")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("technology_NLP_1")))])
          ]),
          _c("li", [
            _c("h3", [_vm._v(_vm._s(_vm.$t("technology_visualization")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("technology_visualization_1")))])
          ]),
          _vm._m(0)
        ]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/develop_1.png"),
            alt: "이미지"
          }
        })
      ])
    ]),
    _c("h2", [
      _c("img", {
        attrs: {
          src: require("@/assets/images/reviewmind/circle.png"),
          alt: "이미지"
        }
      }),
      _vm._v(" " + _vm._s(_vm.$t("technology_context_title")) + " ")
    ]),
    _c("ul", [
      _c("li", [
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/develop_2.png"),
            alt: "로고"
          }
        }),
        _c("h2", [
          _vm._v(" " + _vm._s(_vm.$t("technology_analysis")) + " "),
          _c("span", [_vm._v(_vm._s(_vm.$t("technology_analysis_1")))]),
          _c("p", [
            _vm._v(" " + _vm._s(_vm.$t("technology_analysis_text")) + " ")
          ])
        ]),
        _c("div")
      ]),
      _c("li", [
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/develop_6.png"),
            alt: "로고"
          }
        }),
        _c("h2", [
          _vm._v(" " + _vm._s(_vm.$t("technology_topic")) + " "),
          _c("span", [_vm._v(_vm._s(_vm.$t("technology_topic_1")))]),
          _c("p", [_vm._v(" " + _vm._s(_vm.$t("technology_topic_text")) + " ")])
        ]),
        _c("div", { staticClass: "li_2" })
      ]),
      _c("li", [
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/develop_4.png"),
            alt: "로고"
          }
        }),
        _c("h2", [
          _vm._v(" " + _vm._s(_vm.$t("technology_text")) + " "),
          _c("span", [_vm._v(_vm._s(_vm.$t("technology_text_1")))]),
          _c("p", [_vm._v(" " + _vm._s(_vm.$t("technology_text_text")) + " ")])
        ]),
        _c("div", { staticClass: "li_3" })
      ]),
      _c("li", [
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/develop_3.png"),
            alt: "로고"
          }
        }),
        _c("h2", [
          _vm._v(" " + _vm._s(_vm.$t("technology_recognition")) + " "),
          _c("span", [_vm._v(_vm._s(_vm.$t("technology_recognition_1")))]),
          _c("p", [
            _vm._v(" " + _vm._s(_vm.$t("technology_recognition_text")) + " ")
          ])
        ]),
        _c("div", { staticClass: "li_4" })
      ]),
      _c("li", [
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/develop_5.png"),
            alt: "로고"
          }
        }),
        _c("h2", [
          _vm._v(" " + _vm._s(_vm.$t("technology_machine")) + " "),
          _c("span", [_vm._v(_vm._s(_vm.$t("technology_machine_1")))]),
          _c("p", [_vm._v(_vm._s(_vm.$t("technology_machine_text")))])
        ]),
        _c("div", { staticClass: "li_5" })
      ]),
      _c("li")
    ]),
    _c("div", { staticClass: "mainSection4" }, [
      _c("div", { staticClass: "section4Wrap" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("demo_request")))]),
        _c("a", { attrs: { href: "tel:02-713-5847" } }, [
          _c("button", [_vm._v(_vm._s(_vm.$t("support")))])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("img", {
        attrs: {
          src: require("@/assets/images/reviewmind/logo3.png"),
          alt: "로고"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }